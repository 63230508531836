import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { map } from '../../../functions/numberMap';

export const useRainbow = () => {
  const theme = useTheme();
  return useMemo(
    () => [
      theme.palette.picker.blue,
      theme.palette.picker.cyan,
      theme.palette.picker.lime,
      theme.palette.picker.orange,
      theme.palette.picker.pink,
    ],
    [theme],
  );
};

export const useGetRainbowColor = () => {
  const theme = useTheme();
  const rainbow = useRainbow();
  return useCallback(
    (index: number | undefined) => (index === undefined || index < 0 ? theme.palette.black : rainbow[index % rainbow.length]),
    [rainbow, theme.palette.black],
  );
};

export const useRainbowColor = (index: number | undefined) => {
  const getRainbowColor = useGetRainbowColor();
  return useMemo(() => getRainbowColor(index), [getRainbowColor, index]);
};

export const useRainbowGradient = (length: number) => {
  const rainbow = useRainbow();
  return useMemo(() => {
    if (length === 0) return undefined;
    if (length === 1) return rainbow[0];
    return `linear-gradient(to bottom right, ${Array.from({ length }, (_, index) => `${rainbow[index % rainbow.length]} ${map(index, [0, length - 1], [0, 100])}%`).join(', ')})`;
  }, [length, rainbow]);
};
