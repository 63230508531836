export default {
  title: {
    newModel: 'Nouveau modèle',
    responseVariable: 'Variable expliquée',
    explanatoryVar: 'Variables explicatives',
    period: 'Période de référence',
    params: 'Paramètres',
    equation: 'Équation',
    variables: 'Variables',
    vncEquation: 'Équation',
    newModelDialog: 'Nouveau modèle',
  },
  subtitle: {
    noMode: "Ce modèle n'est pas encore paramétré",
  },
  text: {
    noVariables: 'Pas de variables',
    noYVariable: 'Pas de variable expliquée',
    noXVariables: 'Pas de variables explicatives',
  },
  typography: {
    formula: 'Équation',
    r2: 'R²',
    mape: 'MAPE',
    rmse: 'RMSE',
    min: 'Min',
    max: 'Max',
    coefficient: 'Coefficient',
    pValue: 'Valeur-p',
    standardDeviation: 'Écart type',
    covariance: 'Covariance',
  },
  button: {
    create: 'Créer',
    cancel: 'Annuler',
    rlmMode: "Modéliser cette variable avec d'autres variables",
    rlmMode_short: 'Variable',
    toggleRlmMode: 'Choisir des variables plutôt',
    equationMode: "Écrire l'équation directement",
    equationMode_short: 'Équation',
    toggleEquationMode: "Écrire l'équation plutôt",
    editModel: 'Modifier ce modèle',
    saveModel: 'Enregistrer ce modèle',
    createModel: 'Créer ce modèle',
    duplicateModel: 'Dupliquer ce modèle',
    deleteModel: 'Supprimer ce modèle',
    cancelChanges: 'Annuler les modifications',
    modifyYVariable: 'Choisir',
    modifyYVariable_long: 'Choisir la variable expliquée',
    addXVariable: 'Ajouter',
    chooseCalculationVariable: 'Choisir une variable existante',
    chooseCalculationVariable_short: 'Variable',
    choosePointsVariable: 'Insérer des données manuelles',
    choosePointsVariable_short: 'Données',
  },
  input: {
    label: {
      groupBy: 'Sous-périodes',
      name: 'Nom',
      from: 'Date de début',
      to: 'Date de fin',
    },
  },
  tooltip: {
    createModel: 'créer un modèle',
    createInstanceModel: 'créer une instance du modèle',
    duplicateModel: 'dupliquer ce modèle',
    editModel: 'modifier ce modèle',
    deleteModel: 'supprimer ce modèle',
    kindRequired: "Vous ne pouvez pas enregistrer un modèle sans choisir sa variante d'abord",
    yVarRequired: 'Vous ne pouvez pas enregistrer un modèle sans variable expliquée',
    hideXVar: 'Variable affichée sur le graphique',
    showXVar: 'Variable cachée sur le graphique',
    enableXVar: 'Variable non prise en compte dans le modèle',
    disableXVar: 'Variable prise en compte dans le modèle',
    deleteXVar: 'Supprimer cette variable',
    preview: {
      line: 'Courbe',
      column: 'Diagramme à barres',
    },
    r2: "Coefficient de détermination. Le R² mesure la variance expliquée par le modèle. Il varie entre 0 et 1. Un R² = 1 indique que le modèle explique toute la variabilité des données, tandis qu'un R² = 0 indique qu'il n'explique aucune variabilité. Plus le R² est proche de 1, plus le modèle est capable de capturer les relations entre les variables.",
    mape: "Erreur moyenne absolue en pourcentage. Le MAPE donne une idée de l'erreur en pourcentage. Plus faible est le pourcentage, meilleur est le modèle.",
    rmse: "Racine de l'erreur quadratique moyenne. Le RMSE mesure la précision du modèle. Il indique à quel point, en moyenne, les prédictions s'éloignent des valeurs réelles. Plus bas est le RMSE, meilleure est la performance du modèle.",
    pValue:
      "La valeur-p mesure la probabilité que l'impact de cette variable soit lié au hasard. Elle est comprise entre 0 et 1, et doit être la plus faible possible. Le seuil de 0,05 est souvent utilisé comme seuil de confiance.",
    standardDeviation:
      "Écart type. Il mesure la dispersion des valeurs autour de la moyenne. Plus l'écart type est faible, plus les valeurs sont proches de la moyenne.",
  },
  highcharts: {
    modelSeries: 'Modèle',
  },
  dataGrid: {
    model: 'Modèle',
  },
};
