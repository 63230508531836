import { useMemo } from 'react';

import { BoxInfo, CreateBoxBody, IsModbusTcpDevice, IsOpcUaDevice, UUID, UpdateBoxBody } from '@dametis/core';
import { Request } from '@dametis/sdk';

import { sdk } from 'sdk';

import store, { RootState, useSelector } from '../index';

import { api } from './index';

export const boxesApi = api.injectEndpoints({
  endpoints: build => ({
    readAllBoxes: build.query<BoxInfo[], void>({
      providesTags: ['Boxes'],
      queryFn: async () => {
        try {
          const { data } = await sdk.box.ListAll();
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    readBoxes: build.query<BoxInfo[], { groupId?: UUID; siteId?: UUID } | void>({
      providesTags: ['Boxes'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const groupId = (arg && arg.groupId) ?? state.auth.selectedGroup!.uuid;
        const siteId = (arg && arg.siteId) ?? state.auth.selectedSite?.uuid;
        try {
          const { data } = await (siteId ? sdk.box.List(siteId) : sdk.corporate.ListBoxes(groupId));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createBox: build.mutation<BoxInfo, { siteId: UUID; box: CreateBoxBody }>({
      invalidatesTags: ['Boxes'],
      queryFn: async ({ siteId, box }) => {
        try {
          const { data } = await sdk.box.Create(siteId, box);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateBox: build.mutation<BoxInfo, { uuid: UUID; updates: UpdateBoxBody }>({
      invalidatesTags: ['Boxes'],
      queryFn: async ({ uuid, updates: body }) => {
        try {
          const { data } = await sdk.box.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteBox: build.mutation<void, UUID>({
      invalidatesTags: ['Boxes'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.box.Delete(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    restoreBox: build.mutation<UUID, { activityId: UUID; boxId: UUID }>({
      invalidatesTags: ['Boxes'],
      queryFn: async ({ activityId, boxId }) => {
        try {
          await sdk.box.Restore(boxId, activityId);
          return { data: boxId };
        } catch (error) {
          return { error };
        }
      },
    }),
    synchronizeBox: build.mutation<BoxInfo, { uuid: UUID; request?: Request<{ force: boolean }, never> }>({
      queryFn: async ({ uuid, request }) => {
        try {
          const { data } = await sdk.box.SyncConfig(uuid, request);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const getBoxesMaxLoopTime = (boxes: BoxInfo[]) => {
  let maxLoopTime = 5000;

  boxes.forEach(box => {
    box.devices?.forEach(device => {
      if (IsModbusTcpDevice(device) && device.modbusTcp.loopTime > maxLoopTime) {
        maxLoopTime = device.modbusTcp.loopTime;
      }
      if (IsOpcUaDevice(device) && device.opcUa.loopTime > maxLoopTime) {
        maxLoopTime = device.opcUa.loopTime;
      }
    });
  });
  return maxLoopTime;
};

export const useBoxesMaxLoopTime = () => {
  const { data: boxes } = useBoxes();

  return useMemo(() => getBoxesMaxLoopTime(boxes ?? []), [boxes]);
};

export const {
  useCreateBoxMutation,
  useDeleteBoxMutation,
  useReadAllBoxesQuery,
  useReadBoxesQuery,
  useRestoreBoxMutation,
  useSynchronizeBoxMutation,
  useUpdateBoxMutation,
  endpoints: boxesEndpoints,
} = boxesApi;

export const selectBoxes: typeof boxesApi.endpoints.readBoxes.select = arg =>
  boxesApi.endpoints.readBoxes.select(
    arg ?? { groupId: store.getState().auth.selectedGroup?.uuid, siteId: store.getState().auth.selectedSite?.uuid },
  );

export const useBoxes: typeof useReadBoxesQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadBoxesQuery(arg ?? { groupId, siteId }, { skip: !siteId && !groupId, ...options });
};
