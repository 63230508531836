/* eslint-disable react/destructuring-assignment */
import { isEqual } from 'lodash-es';

import {
  CalculationVariable,
  FillOperators,
  IsAlarmVariable,
  IsAliasVariable,
  IsBatchVariable,
  IsBlockTypeMetricVariable,
  IsBlockTypeParameterVariable,
  IsBlockVariable,
  IsCalculationVariable,
  IsDataVariable,
  IsModelVariable,
  IsPointsVariable,
  ModelKeyField,
  NewUUIDv5,
  VarCalc,
  setOptionsToLeaves,
} from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import { exhaustiveCheck } from '../../types';

export const keepRootProps = (calculation: CalculationVariable): Partial<CalculationVariable> => ({
  ...(calculation.period && { period: calculation.period }),
  ...(calculation.timeZone && { timeZone: calculation.timeZone }),
  ...(calculation.now && { now: calculation.now }),
  ...(calculation.maxOutputPoints && { maxPoints: calculation.maxOutputPoints }),
  ...(calculation.targetedOutputPoints && { targetPoints: calculation.targetedOutputPoints }),
  ...(calculation.autoGroupByReplacement && { autoGroupByReplacement: calculation.autoGroupByReplacement }),
  ...(calculation.stats && { stats: calculation.stats }),
});

export const removeRootProps = ({
  period,
  timeZone,
  maxOutputPoints,
  targetedOutputPoints,
  autoGroupByReplacement,
  ...rest
}: CalculationVariable): CalculationVariable => rest;

export const addLevel = (calculation: CalculationVariable, rootProps: Partial<CalculationVariable> = {}): CalculationVariable => ({
  exp: 'var_0',
  vars: {
    var_0: removeRootProps(calculation),
  },
  ...keepRootProps(calculation),
  ...rootProps,
});

export const applySmartFill = (calculation: CalculationVariable): CalculationVariable => {
  if (getSingleVariableCalculation(calculation)) {
    setOptionsToLeaves(calculation, { fill: FillOperators.NULL }, false);
  }
  return calculation;
};

export const AreSameVarCalc = (varCalc1: VarCalc, varCalc2: VarCalc): boolean => {
  if (IsCalculationVariable(varCalc1) || IsCalculationVariable(varCalc2)) {
    return isEqual(varCalc1, varCalc2);
  }
  if (IsDataVariable(varCalc1) && IsDataVariable(varCalc2)) {
    return varCalc1.variableUuid === varCalc2.variableUuid;
  }
  if (IsPointsVariable(varCalc1) && IsPointsVariable(varCalc2)) {
    return isEqual(varCalc1.points, varCalc2.points);
  }
  if (IsBatchVariable(varCalc1) && IsBatchVariable(varCalc2)) {
    return varCalc1.batchUuid === varCalc2.batchUuid;
  }
  if (IsBlockVariable(varCalc1) && IsBlockVariable(varCalc2)) {
    return varCalc1.blockId === varCalc2.blockId && varCalc1.blockKey === varCalc2.blockKey;
  }
  if (IsModelVariable(varCalc1) && IsModelVariable(varCalc2)) {
    return (
      varCalc1.modelUuid === varCalc2.modelUuid &&
      varCalc1.modelKey === varCalc2.modelKey &&
      (varCalc1.modelKey !== ModelKeyField.X_VAR ||
        varCalc2.modelKey !== ModelKeyField.X_VAR ||
        varCalc1.modelXVarUuid === varCalc2.modelXVarUuid)
    );
  }
  if (IsAlarmVariable(varCalc1) && IsAlarmVariable(varCalc2)) {
    return varCalc1.alarmUuid === varCalc2.alarmUuid;
  }
  if (IsAliasVariable(varCalc1) && IsAliasVariable(varCalc2)) {
    return varCalc1.aliasUuid === varCalc2.aliasUuid;
  }
  return false;
};

export enum VarCalcKinds {
  DATA_VARIABLE = 'dataVariable',
  POINTS_VARIABLE = 'pointsVariable',
  BATCH_VARIABLE = 'batchVariable',
  BLOCK_VARIABLE = 'blockVariable',
  CALCULATION_VARIABLE = 'calculationVariable',
  BLOCK_TYPE_METRIC_VARIABLE = 'blockTypeMetricVariable',
  BLOCK_TYPE_PARAMETER_VARIABLE = 'blockTypeParameterVariable',
  MODEL_VARIABLE = 'modelVariable',
  ALARM_VARIABLE = 'alarmVariable',
  ALIAS_VARIABLE = 'aliasVariable',
}

export const GetVarCalcKind = (varCalc: VarCalc): VarCalcKinds | null => {
  if (IsDataVariable(varCalc)) {
    return VarCalcKinds.DATA_VARIABLE;
  }
  if (IsPointsVariable(varCalc)) {
    return VarCalcKinds.POINTS_VARIABLE;
  }
  if (IsBatchVariable(varCalc)) {
    return VarCalcKinds.BATCH_VARIABLE;
  }
  if (IsBlockVariable(varCalc)) {
    return VarCalcKinds.BLOCK_VARIABLE;
  }
  if (IsCalculationVariable(varCalc)) {
    return VarCalcKinds.CALCULATION_VARIABLE;
  }
  if (IsBlockTypeMetricVariable(varCalc)) {
    return VarCalcKinds.BLOCK_TYPE_METRIC_VARIABLE;
  }
  if (IsBlockTypeParameterVariable(varCalc)) {
    return VarCalcKinds.BLOCK_TYPE_PARAMETER_VARIABLE;
  }
  if (IsModelVariable(varCalc)) {
    return VarCalcKinds.MODEL_VARIABLE;
  }
  if (IsAlarmVariable(varCalc)) {
    return VarCalcKinds.ALARM_VARIABLE;
  }
  if (IsAliasVariable(varCalc)) {
    return VarCalcKinds.ALIAS_VARIABLE;
  }
  exhaustiveCheck(varCalc);
  return null;
};
export const GetVarCalcUniqueId = (varCalc: VarCalc): string | null => {
  if (IsDataVariable(varCalc)) {
    return varCalc.variableUuid;
  }
  if (IsPointsVariable(varCalc)) {
    return NewUUIDv5(JSON.stringify(varCalc.points));
  }
  if (IsBatchVariable(varCalc)) {
    return varCalc.batchUuid;
  }
  if (IsBlockVariable(varCalc)) {
    return `${varCalc.blockId}_${varCalc.blockKey}`;
  }
  if (IsCalculationVariable(varCalc)) {
    return NewUUIDv5(JSON.stringify(varCalc));
  }
  if (IsBlockTypeMetricVariable(varCalc)) {
    return varCalc.blockKey;
  }
  if (IsBlockTypeParameterVariable(varCalc)) {
    return varCalc.blockKey;
  }
  if (IsModelVariable(varCalc)) {
    return `${varCalc.modelUuid}_${varCalc.modelKey}${varCalc.modelKey === ModelKeyField.X_VAR ? `_${varCalc.modelXVarUuid}` : ''}`;
  }
  if (IsAlarmVariable(varCalc)) {
    return varCalc.alarmUuid;
  }
  if (IsAliasVariable(varCalc)) {
    return varCalc.aliasUuid;
  }
  exhaustiveCheck(varCalc);
  return null;
};
