import { interpolationSearch } from '@dametis/core';

export const safeInterpolationSearch = (
  arrayX: (number | undefined)[],
  arrayY: (number | undefined)[],
  x: number,
  y: number | undefined,
): number | null => {
  if (y === undefined) {
    return null;
  }
  try {
    const index = interpolationSearch(arrayX, arrayY, x, y, 0, arrayX.length - 1);
    // x is after found element ("fillPrevious") but not too far (< 60secs)
    return x - arrayX[index] >= 0 && x - arrayX[index] < 60000 ? index : null;
  } catch (err) {
    console.timeEnd('safeInterpolationSearch');
    console.error(`safeInterpolationSearch ${err.message}`);
  }
  return null;
};
