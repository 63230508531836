import { AxiosError, CanceledError } from 'axios';
import i18next from 'i18next';

import { isExplicitError } from '@dametis/core';

import { ParsedError } from 'errors/parseErrors';
import { TypedThunk } from 'store';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import { DEFAULT_ERROR as DEFAULT_ERROR_EN } from '../../localization/en/errors';
import { DEFAULT_ERROR as DEFAULT_ERROR_FR } from '../../localization/fr/errors';

export const displayTadaErrorToasts =
  (errors: ParsedError[]): TypedThunk<void> =>
  dispatch => {
    errors.forEach(error => {
      dispatch(addToast({ message: error.message, severity: ToastSeverity.ERROR }));
    });
  };

export const displaySdkErrorToast =
  (error: any, fallbackError?: string): TypedThunk<void> =>
  dispatch => {
    if (error instanceof CanceledError) return;
    if (error instanceof AxiosError && isExplicitError(error.response?.data)) {
      const translatedError = i18next.t(`errors:alerts.${error.response.data.errorName}`);
      if (fallbackError && (translatedError === DEFAULT_ERROR_EN || translatedError === DEFAULT_ERROR_FR)) {
        dispatch(addToast({ message: fallbackError, severity: ToastSeverity.ERROR }));
      } else {
        dispatch(addToast({ message: translatedError, severity: ToastSeverity.ERROR }));
      }
    } else {
      dispatch(addToast({ message: i18next.t(`toast:error`), severity: ToastSeverity.ERROR }));
    }
  };
