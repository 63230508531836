import i18next from 'i18next';

import { ErrorName, TadaApiResponse } from '@dametis/core';

export interface ParsedError {
  errorName: ErrorName;
  message: string;
}

export const hasTadaErrors = (data: TadaApiResponse[]) => data.some(d => d.errors?.length);

export const toParsedError = (errorName: ErrorName): ParsedError => ({
  errorName,
  message: i18next.t(`errors:alerts.${errorName}`),
});

export const parseTadaErrors = (tadas: TadaApiResponse[]) => {
  const distinctErrors = new Set<ErrorName>();
  tadas.forEach(tada => {
    if (tada.errors !== undefined && tada.errors.length > 0) {
      tada.errors.forEach(error => {
        distinctErrors.add(error.errorName);
      });
    }
  });
  return Array.from(distinctErrors.values()).map(error => toParsedError(error));
};
